import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import * as languageAction from '../../store/actions/LanguageAction';
import { withStyles } from '@material-ui/core/styles';
// import { DataGrid } from '@material-ui/data-grid';
// import CheckIcon from '@material-ui/icons/Check';
// import ClearIcon from '@material-ui/icons/Clear';
import PlayerUI from '../Players/PlayerUI';
import PlayerHead from '../Players/PlayerHead';
import Users from '../User/users';


let style = theme => ({
    root: {
        display: 'block',
        backgroundColor: "#282c34",
        overflow: "scroll",
        color: "white",
        '& .table_header_red': {
            color: 'red',
        },
        '& .booleanCell': {
            color: 'red',
        },
        '.MuiDataGrid-booleanCell[data-value="false"]': {
            color: 'red'
        }
    },
    MuiDataGridBooleanCell: {
        color: 'white',
    },

    icons: {
        display: 'flex',
        alignItems: 'center'
    }

})




class Players extends React.Component {
    state = {
        checkOnline: false,
        own: '*',
        pageOpen: 'players'
    }

    handleCheckOnline() {
        this.setState({
            checkOnline: !this.state.checkOnline,

        })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    getPlayers() {
        const { players } = this.props.page;
        return players.map((player, i) => {
            return {
                id: player.id,
                name: player.name,
                comment: player.comment,
                mode: player.mode,
                own: player.own,
                online: player.online,
                version: player.setings.VersionName,
                freediscspace: player.setings.freediscspace,
                expires: new Date(player.expires),
                created: new Date(player.created)
            }
        })
    }

    handleChangePage(page) {
        this.setState({
            pageOpen: page
        })
    }

    render() {
        const { classes, handleChangeTheme } = this.props;
        const { usersdata } = this.props.user;
        const { players } = this.props.page;
        const { checkOnline, own, pageOpen } = this.state;
        let count = 0;

        return (
            <div style={{ height: window.innerHeight - 35 }} className={classes.root} >
                <PlayerHead handleCheckOnline={this.handleCheckOnline.bind(this)} state={this.state} handleChange={this.handleChange.bind(this)} handleChangeTheme={handleChangeTheme.bind(this)} handleChangePage={this.handleChangePage.bind(this)} pageOpen={this.state.pageOpen} />

                {
                    usersdata !== undefined ?
                        pageOpen === 'players' ? players
                            .filter(player => checkOnline ? player.online : true)
                            .filter(player => String(player.own) === String(own))
                            // .filter(player => own !== '*' ? String(player.own) === String(own) : true)
                            .map((player, index) => {
                                // if (count <= 10) {
                                //     count += 1
                                const user = usersdata.filter(usr => usr.id === player.own)[0]
                                return <PlayerUI player={player} key={index} usr={user} />
                                // } return null
                            }) : <Users own={own} />

                        : null
                }

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page,
        language: state.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        languageActions: bindActionCreators(languageAction, dispatch)
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Players)));