import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// import Avatar from '@material-ui/core/Avatar';
import logo from '../../img/logo2.1.png';
import InfoIcon from '@material-ui/icons/ExitToApp';


const styles = {
  root: {
    flexGrow: 1,
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '1'
  },
  grow: {
    flexGrow: 1,
    'text-align': 'center',
  },
  logo: {
    marginLeft: -12,
    marginRight: 20,
  },
  button: {
    borderRadius: '4px'
  },
  avatar: {
    backgroundColor: 'white',
    borderRadius: '20% !important',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  // logo: {
  //   fontSize: '2.5rem',
  //   color: 'blue'
  // },
  img: {
    width: '2.2rem'
  }
};


class ButtonAppBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  handleOpenInfo() {
    const { logout, token } = this.props;
    // window.open(`http://revip.org/landing${lang}.html`, "_blank");
    logout(token);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar style={{ backgroundColor: '#7593b3' }}>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.handleOpenInfo.bind(this)}>
              <InfoIcon style={{ fontSize: 24 }} />
            </IconButton>
            <Typography variant="body1" color="inherit" className={classes.grow}>
              Remote Video Player [ADMIN PAGE]
            </Typography>
            {/* <IconButton className={classes.button} href="/user"> */}
            {/* <Avatar variant="rounded" className={classes.avatar}> */}
            <img src={logo} alt='' className={classes.img} />
            {/* </Avatar> */}
            {/* </IconButton> */}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
};

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonAppBar);