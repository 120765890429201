import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import * as languageAction from '../../store/actions/LanguageAction';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { prettiDateTime, shortDate, prettiNum } from '../../services/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AlertDialog from '../Other/AlertDialog';

let style = theme => ({
    container: {
        display: 'flex',
        height: '60px',
        backgroundColor: '#282c34e0',
        flexDirection: 'column'
    },
    component: {
        display: 'flex',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: 'lightgray',
        fontSize: '12px'
    },
    spacer: {
        flex: '1 1 100%',
    },
    text: {
        display: 'flex',
        paddingTop: '5px',
        width: '200px'
    },
    columnsmall: {
        width: '80px',
        display: 'flex',
        paddingTop: '15px'
    },
    columnbig: {
        width: '200px',
        display: 'flex',
        paddingTop: '15px',
        textAlign: 'justify',
    },
    columnbigWP: {
        width: '200px',
        display: 'flex',
        // paddingTop: '10px',
        textAlign: 'justify',
    },
    column: {
        width: '100px',
        display: 'flex',
        paddingTop: '5px'
    },
    downloadicon: {
        color: 'gray',
        paddingTop: '-20px',
        width: '1.1rem'
    },
    role: {
        paddingRight: '10px',
        paddingTop: '15px'
    }

})

class User extends React.Component {
    state = {
        roleConfirmAlert: false,
        newRole: null
    }




    render() {
        const { classes, key, usersdata, handleChangeRole } = this.props;
        const { roleConfirmAlert } = this.state;
        const roles = ['user', 'admin', 'corp'];

        return (
            <div style={{ padding: '5px' }}>
                <Card>
                    <div className={classes.container}>
                        <table key={key}>
                            <tbody key={key}>
                                <tr className={classes.component} key={key}>
                                    <td className={classes.columnsmall} >
                                        id: {usersdata.id}
                                    </td>
                                    <td className={classes.columnbig} >
                                        name: {usersdata.username}
                                    </td>
                                    <td className={classes.columnbig} >
                                        Balance: {prettiNum(usersdata.balance)} р.
                                    </td>
                                    <td className={classes.columnbigWP} >
                                        <a className={classes.role}>Role: </a>
                                        <FormControl className={classes.formControl}>
                                            <Select
                                                style={{ color: 'gray' }}
                                                native
                                                value={usersdata.role}
                                                onChange={handleChangeRole('newRole')}
                                                inputProps={{
                                                    name: 'own',
                                                    id: 'own',
                                                }}
                                            >
                                                {roles
                                                    .map((v, key) => (
                                                        <option key={key} value={v}>{v}</option>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </td>
                                    {usersdata.errormessage === ' ' ? <td className={classes.columnbig} style={{ fontSize: '12px' }}>
                                        Error: {usersdata.errormessage}
                                    </td> : null}
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </Card>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page,
        language: state.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        languageActions: bindActionCreators(languageAction, dispatch)
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(User)));