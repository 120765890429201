import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import actionMiddleware from './actions/ActionMiddleware';
import { createFilter, createBlacklistFilter } from 'redux-persist-transform-filter';

export var store;

function configureStore(initialSate) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const saveSubsetFilter = createBlacklistFilter('user', ['fileProgress', 'logo']);
    // const logger = createLogger()
    const persistConfig = {
        key: 'root',
        blacklist: ['page'],
        storage: storage,
        transforms: [
            saveSubsetFilter
        ]
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer)

    store = createStore(persistedReducer, initialSate,
        composeEnhancers(
            applyMiddleware(thunk))); //, actionMiddleware

    let persistor = persistStore(store, {
        // transforms: [
        //     saveSubsetFilter
        // ]
    });
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers')
            store.replaceReducer(nextRootReducer)
        })
    }
    return { store, persistor }
}

export default configureStore;
