import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import Packets from '../Playlists/packets';

let style = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop: '3rem'
    },
});

class Playlist extends React.Component {

    render() {
        const { open, handlePlaylist, id, name } = this.props;
        const { pacsplaylists, content } = this.props.page;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handlePlaylist}
                    fullWidth={true}
                    maxWidth='xl'
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"

                >
                    <DialogTitle id="scroll-dialog-title" style={{ backgroundColor: 'gray' }}>Device ID: {id} ({name})</DialogTitle>
                    <DialogContent dividers={true} style={{ backgroundColor: 'gray' }}>

                        This is content
                        <Packets pacs={pacsplaylists} content={content} />

                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Playlist));