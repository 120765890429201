import io from 'socket.io-client';
import * as constants from '../constants/urls';
import * as pageActions from '../store/actions/PageActions';

export const sock = null;

class Socket {
  sio = null;
  token = '';
  id = '';

  connect(token, id) {
    const ssio = io.connect(`${constants.ROOT_API_URL}${constants.PATHS.sio}`, {
      transports: ['websocket', 'polling'],
      query: { token }
    });
    this.id = id;
    this.subscribe(ssio);
    this.sio = ssio;
    this.token = token;
    return ssio;
  }

  diconnect() {
    if (this.sio) this.sio.disconnect(true);
  }

  subscribe(socket) {
    socket.on('reconnect_attempt', () => console.log('SIO >>>  reconnect_attempt'));
    socket.on('player', data => console.log('SIO >>> PLAYER', data));
    socket.on('position', data => pageActions.setPositionFull(data));
    socket.on('progress', data => pageActions.setProgress(data, this.token));
    socket.on('positionshort', data => pageActions.setPosition(data));
    socket.on('uicommand', data => pageActions.uicommand(this.token, data));
    socket.on('playerFullInfo', data => pageActions.setFullPlayerInfo(data));
    socket.on('connect', () => {
      socket.emit('join', this.id.toString());
      // console.log('SIO >>> Connect & Join to room: ', this.id);
    });
    socket.on('disconnect', () => console.log('SIO >>> disconnect'));
    socket.on('connect_error', () => console.log('SIO >>> connect_error'));
    socket.on('reconnect_error', () => console.log('SIO >>> reconnect_error'));
    socket.on('error', data => pageActions.showError(data));
    socket.on('snack', data => pageActions.addSnack(data));
  }

  emit(type, data) {
    // console.log('SIO:', this.sio, 'Type:', type, 'Data:', data)
    this.sio.emit(type, data);
  }
}

export default new Socket();