import i18next from 'i18next';

export function convertTiming(timing) {
  if (timing === null) { return "00:00:00" }
  const time = timing.split(':')
  switch (time[0]) {
    case '00':
      time[0] = i18next.t("All_days");
      break;
    case '01':
      time[0] = i18next.t("Monday");
      break;
    case '02':
      time[0] = i18next.t("Tuesday");
      break;
    case '03':
      time[0] = i18next.t("Wednesday");
      break;
    case '04':
      time[0] = i18next.t("Thursday");
      break;
    case '05':
      time[0] = i18next.t("Friday");
      break;
    case '06':
      time[0] = i18next.t("Saturday");
      break;
    case '07':
      time[0] = i18next.t("Sunday");
      break;
    default:
      break;
  }

  if (time[1] === '25') {
    time[1] = i18next.t("All_hour");
  }

  if (time[2] === '61') {
    time[1] = i18next.t("All_minutes");
  }
  return String(time[0] + ':' + time[1] + ':' + time[2])
}

export function calculateTime(times) {
  var totalSec = 0;

  times.map(t => {
    const time = t.split(":");
    const sec = (parseInt(time[0]) * 3600) + (parseInt(time[1]) * 60) + parseInt(time[2]);
    totalSec = totalSec + sec;
  });
  const h = totalSec / 3600 | 0;
  const m = totalSec / 60 - h * 60 | 0;
  const s = totalSec % 60;
  return String(('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2));
}

export function prityTime(sec) {
  const h = sec / 3600 | 0;
  const m = sec / 60 - h * 60 | 0;
  const s = sec % 60;
  return String(('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2));
}

export function ArrayGenerate(n) {
  var i = 0;
  var arr = [];
  if (n !== 60) {
    i = 1;
    arr = [{
      value: n + 1,
      label: i18next.t("All")
    },
    {
      value: '00',
      label: '00'
    },
    ];
  }

  var js = {};

  while (i < n) {
    js = {
      value: String('00' + i).slice(-2),
      label: String('00' + i).slice(-2)
    }
    arr.push(js);
    i++;
  }
  return arr;
}

export function typeRanges() {
  const typeRanges = [
    {
      value: 'content',
      label: i18next.t("Content"),
    },
    {
      value: 'advertising',
      label: i18next.t("Advertising"),
    },
    {
      value: 'mixed',
      label: i18next.t("Mixed")
    }
  ];
  return typeRanges;
}

export function dayRanges() {
  const DayRanges = [
    {
      value: '00',
      label: i18next.t("All days"),
    }, {
      value: '01',
      label: i18next.t("Monday"),
    }, {
      value: '02',
      label: i18next.t("Tuesday"),
    }, {
      value: '03',
      label: i18next.t("Wednesday"),
    }, {
      value: '04',
      label: i18next.t("Thursday"),
    }, {
      value: '05',
      label: i18next.t("Friday"),
    }, {
      value: '06',
      label: i18next.t("Saturday"),
    }, {
      value: '07',
      label: i18next.t("Sunday"),
    },
  ];
  return DayRanges;
}

export function prettiNum(num) {
  if (num === undefined) { return }
  var n = num.toString();
  return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' ');
}

export function modeToName(modes, mode) {
  var name = ''
  modes.map(m => {
    if (m.mode === mode) {
      console.log('RETURN: ', m.name)
      name = m.name;
    }
  });
  return name;
}

export function prettiDateTime(date) {
  if (date === undefined) { return "" }
  const d = date.split("/")
  const h = (0 + d[1]).slice(-2)
  const m = (0 + d[2]).slice(-2)
  return String(h + ":" + m)
}

export function getImageSizeInBytes(imgURL) {
  var request = new XMLHttpRequest();
  request.open("HEAD", imgURL, false);
  request.send(null);
  var headerText = request.getAllResponseHeaders();
  var re = /Content\-Length\s*:\s*(\d+)/i;
  re.exec(headerText);
  return parseInt(RegExp.$1);
}

export function getConnectionSpeedToServerInMs(imgURL, count = 2) {
  var testImg = new Image();
  var times = [];
  for (let i = 0; i < count; i++) {
    const tStart = new Date().getTime();
    testImg.src = imgURL + '?t=' + tStart;
    testImg.onload = function () {
      const tEnd = new Date().getTime();
      const tTimeTook = tEnd - tStart;
      times.push(tTimeTook);
    }
  }
  const sum = times.reduce(function (a, b) { return a + b; });
  const avg = sum / times.length;
  return avg
}

export function getConnectionSpeedToServerInMb(imgURL, count = 2) {
  const ms = getConnectionSpeedToServerInMs(imgURL, count);
  const mb = getImageSizeInBytes(imgURL);
  return (mb / ms * 1000).toFixed(2)
}

export function shortDate(date) {
  if (date === undefined) { return '' }
  const d = date.split(' ')
  return d[0] + ' ' + d[1].slice(0, 1) + ' ' + d[2].slice(0, 2) + ' ' + d[3].slice(0, 1) + ' ' + d[4].slice(0, 2) + ' ' + d[5].slice(0, 1)
}