import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import * as languageAction from '../../store/actions/LanguageAction';
import { withStyles } from '@material-ui/core/styles';
import User from './user';
import AlertDialog from '../Other/AlertDialog';

let style = theme => ({
    root: {
        display: 'block',
        backgroundColor: "#282c34",
        overflow: "scroll",
        color: "white",
        '& .table_header_red': {
            color: 'red',
        },
        '& .booleanCell': {
            color: 'red',
        },
        '.MuiDataGrid-booleanCell[data-value="false"]': {
            color: 'red'
        }
    },
    MuiDataGridBooleanCell: {
        color: 'white',
    },

    icons: {
        display: 'flex',
        alignItems: 'center'
    }

})

class Users extends React.Component {
    state = {
        roleConfirmAlert: false,
        newRole: null
    }

    handleChangeRole = name => event => {
        console.log('HandleChangeRole: ', name, event.target.value)
        this.setState({
            roleConfirmAlert: true,
            [name]: event.target.value
        })
    }

    handleChangeRoleConfirm() {

    }

    handleChangeRoleCancel() {
        this.setState({
            roleConfirmAlert: false
        })
    }

    render() {
        const { usersdata } = this.props.user;
        const { own } = this.props;
        const { roleConfirmAlert } = this.state;


        return (
            <div>
                {usersdata
                    .filter(user => own !== '*' ? String(user.id) === String(own) : true)
                    .map((user, index) => {
                        return (
                            <User key={index} usersdata={user} handleChangeRole={this.handleChangeRole.bind(this)} handleChangeRoleConfirm={this.handleChangeRoleConfirm.bind(this)} handleChangeRoleCancel={this.handleChangeRoleCancel.bind(this)} />
                        )
                    })}
                {/* <AlertDialog alert='Warning!!!' message='Please confirm change user role!' handleAgree={this.handleChangeRoleConfirm.bind(this)} handleClose={this.handleChangeRoleCancel.bind(this)} open={roleConfirmAlert} /> */}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page,
        language: state.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        languageActions: bindActionCreators(languageAction, dispatch)
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Users)));