import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

let style = theme => ({
    container: {
        display: 'flex',
        height: '100%',
        backgroundColor: '#282c34e0',
        flexDirection: 'column'
    },
    component: {
        display: 'flex',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    spacer: {
        flex: '1 1 100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

})

var own = [];




class UserSelect extends React.Component {
    state = {
        users: this.props.players
            .filter(v => {
                if (own.includes(v.own)) {
                    return false
                }
                own.push(v.own)
                // console.log("111")
                return true
            })
    }

    filtered(item) {
        if (own.includes(item)) {
            return false;
        }
        own.push(item);
        return true;
    }

    ownToName(own) {
        const { usersdata } = this.props;
        var u = '';
        if (usersdata === undefined) { return '' };
        usersdata.map(user => {
            if (user.id === own) {
                u = user.username;
                return user.username;
            } return null
        });
        return u;
    }

    componentDidMount() {
        const { players } = this.props;

        const pl = players
            .filter(v => {
                if (own.includes(v.own)) {
                    return false
                }
                own.push(v.own)
                v.username = this.ownToName(v.own)
                return true
            })
            .sort((a, b) => {
                console.log(`comparing ${a.own},${b.own}`);
                return a.own > b.own ? 1
                    : a.own === b.own ? 0
                        : -1;
            })

        this.setState({
            players: pl
        })

    }

    render() {
        const { classes, players, handleChange, user, sortById } = this.props;
        var own = [];


        return (
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-user" style={{ color: 'gray' }}>User Name</InputLabel>
                    <Select
                        style={{ color: 'gray' }}
                        native={true}
                        // value={user}
                        onChange={handleChange('own')}
                        inputProps={{
                            name: 'own',
                            id: 'own',
                        }}
                    >
                        {sortById ?
                            <option value="0">Select user ID</option> :
                            <option value="0">Select user name</option>
                        }

                        {players
                            .filter(v => {
                                if (own.includes(v.own)) {
                                    return false
                                }
                                own.push(v.own)
                                v.username = this.ownToName(v.own)
                                return true
                            })
                            .sort((a, b) => {
                                // console.log(`comparing ${a.own},${b.own}`);
                                if (sortById) {
                                    return a.own > b.own ? 1
                                        : a.own === b.own ? 0
                                            : -1;
                                } else {
                                    return a.username.toLowerCase() > b.username.toLowerCase() ? 1 :
                                        a.username.toLowerCase() === b.username.toLowerCase() ? 0 : -1
                                }
                            })
                            .map((v, key) => (
                                sortById ?
                                    <option key={key} value={v.own}>{v.own + " (" + v.username + ")"}</option> :
                                    <option key={key} value={v.own}>{v.username + " (" + v.own + ")"}</option>
                            ))}
                    </Select>
                </FormControl>

            </div>
        )
    }
}

export default withStyles(style)(UserSelect);