import React from 'react';
import '../styles/App.css';
import Singin from './User/SignIn';
import { connect } from 'react-redux';
// import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { bindActionCreators } from 'redux';
import * as pageActions from '../store/actions/PageActions';
import * as userActions from '../store/actions/UserActions';
import * as languageAction from '../store/actions/LanguageAction';
import * as network from '../services';
import AlertDialog from './Other/AlertDialog';
import Header from '../components/Header/AppBarZerro';
import Footer from './Footer/Footer';
import Players from './Players/Players';
export var socket;


const darkTheme = createMuiTheme({
  palette: {
    type: "dark"
  }
});

const lightTheme = createMuiTheme({
  palette: {
    type: "light"
  }
});

class App extends React.Component {
  state = {
    isLoading: false,
    alertUser: false,
    topLevelDark: true,
    setTopLevelDark: false,
  };


  handleLogOut = () => {
    this.props.userActions.logOut(this.props.user.token);
    setTimeout(() => {
      localStorage.clear();
      socket.disconnect();
    }, 500);

  }

  handleAlertOk() {
    this.props.pageActions.clearPageError();
    this.props.userActions.clearUserError();
    this.setState({
      alertUser: false,
      alertPage: false,
      messageUser: false,
      isReSending: false
    });
  }

  handleChangeTheme() {
    console.log('HandleChangeTheme')
    this.setState({
      topLevelDark: !this.state.topLevelDark
    })
  }

  componentDidMount() {
    const { getContent, getPlaylists, getPlayers, getPacsPlaylists } = this.props.pageActions;
    const { token, id, } = this.props.user;
    const { getUserData, getLogo, getusersdata, getModes } = this.props.userActions;
    const { isLoading, } = this.state;

    if (!isLoading) {
      console.log('APP / data is loading');
      getContent(token);
      getUserData(token);
      getPlaylists(token);
      getPlayers(token);
      getLogo(token);
      getusersdata(token);
      getModes(token)
      getPacsPlaylists(token)
      socket = network.socket.connect(token, id)
      this.setState({
        isLoading: true
      });
    }
  }


  render() {
    const { getUserAuthorization, } = this.props.userActions;
    // const { setPlayList, getContent, setPosition, getPosition, deleteContent, renameContent, updatePlayer, getPlaylists, createPlaylist,
    //   getPlayers, getPacsPlaylists } = this.props.pageActions;
    const { token, role } = this.props.user;
    const { alertUser, topLevelDark } = this.state;

    if (this.props.user.error !== "" && !alertUser && this.props.user.error !== undefined) {
      this.setState({
        alertUser: true
      });
    }

    return (
      <MuiThemeProvider theme={topLevelDark ? darkTheme : lightTheme}>
        {token ?
          <div className="App" style={{ height: window.innerHeight }}>
            <header>
              <Header logout={this.props.userActions.logOut} token={token} />
              {/* <div style={{ paddingTop: '10px' }}>ADMIN PAGE</div> */}
              {role === "admin" ?
                <Players handleChangeTheme={this.handleChangeTheme} /> :
                <div>
                  No Permission to enter
                </div>
              }

              <Footer />
              <AlertDialog open={this.state.alertUser} alert="Error!" message={this.props.user.error} handleAgree={this.handleAlertOk.bind(this)} />
            </header>
          </div>
          :
          <div className="App">
            <header className="App-header">
              <Singin getUserAuthorization={getUserAuthorization} />
              <AlertDialog open={this.state.alertUser} alert="Error!" message={this.props.user.error} handleAgree={this.handleAlertOk.bind(this)} />
            </header>
          </div>
        }
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    page: state.page,
    language: state.language
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pageActions: bindActionCreators(pageActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    languageActions: bindActionCreators(languageAction, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
