import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, Grid, Tooltip, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import * as network from '../../services';

let style = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop: '3rem'
    },
    table: {
        minWidth: window.innerWidth - 50,
    },
    date: {
        width: '15%'
    },
    id: {
        width: '5%'
    },
    tag: {
        width: '5%'
    },
    message: {
        // width: '40%'
    },
    button: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        height: '100%',
        backgroundColor: '#282c34e0',
        flexDirection: 'column'
    },
    component: {
        display: 'flex',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    spacer: {
        flex: '1 1 100%',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    paperWidthSm: {
        maxWidth: window.innerWidth - 64
    }
})

class Settings extends React.Component {

    handlePlayerChangeSwitch = name => e => {
        const { own } = this.props.playerdata;
        const socket = network.socket;
        var checked = e.target.checked;
        if (name === "title_size") {
            checked = checked ? 1 : 0
        }
        const newState = {
            setings: {
                ... this.props.playerdata.setings,
                [name]: checked,
                // ['title_message']: this.props.data.setings.titleessage
            }
        }
        // console.log(newState);
        // this.setState((newState, { pldialognotsave: false }))
        socket.emit('play', { 'setings': 1, 'player': this.props.playerdata.mac, 'data': newState.setings, 'ownid': own })
    }


    render() {
        const { open, handleSettings, playerdata, classes, listenerid, listeners } = this.props;
        const { setings, id, name } = this.props.playerdata;
        const { title_message } = this.props.playerdata.setings;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleSettings}
                    fullWidth={true}
                    maxWidth='xl'
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"

                >
                    <DialogTitle id="scroll-dialog-title" style={{ backgroundColor: 'gray' }}>Device ID: {id} ({name})</DialogTitle>
                    <DialogContent dividers={true} style={{ backgroundColor: 'gray' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    // autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Player name"
                                    type="text"
                                    // fullWidth
                                    // ref={(ref) => { this.playerName = ref; }}
                                    // onChange={handlePlayerChangeText('name')}
                                    value={playerdata.name}

                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    // autoFocus
                                    margin="dense"
                                    id="comment"
                                    label="Comment"
                                    type="text"
                                    // fullWidth
                                    // ref={(ref) => { this.playerComment = ref; }}
                                    // onChange={handlePlayerChangeText('comment')}
                                    className={classes.commentfield}
                                    value={playerdata.comment}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                {/* TEST */}
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    id="autoRestartTime"
                                    label="autoRestartTime"
                                    type="text"
                                    // fullWidth
                                    className={classes.commentfield}
                                    value={setings.autoRestartTime}
                                />

                            </Grid>
                            <Grid item xs={3}>
                                {/* TEST */}
                            </Grid>
                            <Grid item xs={3}>
                                {/* TEST */}
                            </Grid>
                            <Grid item xs={3}>
                                {/* TEST */}
                            </Grid>

                        </Grid>

                        <div>

                            <TextField
                                // autoFocus
                                margin="dense"
                                id="title_text"
                                label="Title text or rss link"
                                type="text"
                                fullWidth
                                // ref={(ref) => { this.playerTitle_Message = ref; }}
                                // onChange={handlePlayerChangeTitleMessage()}
                                className={classes.commentfield}
                                value={title_message}
                            />
                        </div>
                        <FormGroup row className={classes.formgroup}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={setings.fs}
                                        onChange={this.handlePlayerChangeSwitch('fs')}
                                        value="fullscreen"
                                    />
                                }
                                label="Progress bar"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={setings.deflogo}
                                        onChange={this.handlePlayerChangeSwitch('deflogo')}
                                        value="deflogo"
                                    />
                                }
                                label="Default logo"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={setings.animateLogo}
                                        onChange={this.handlePlayerChangeSwitch('animateLogo')}
                                        value="animateLogo"
                                        disabled={setings.animateLogo === undefined}
                                    />
                                }
                                label="animateLogo"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={setings.dispid}
                                        onChange={this.handlePlayerChangeSwitch('dispid')}
                                        value="dispid"
                                    />
                                }
                                label="Display ID"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={setings.title}
                                        onChange={this.handlePlayerChangeSwitch('title')}
                                        value="Title"
                                    />
                                }
                                label="title"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={setings.title_size}
                                        onChange={this.handlePlayerChangeSwitch('title_size')}
                                        value="title_size"
                                        disabled={setings.title_size !== undefined ? false : true}
                                    />
                                }
                                label="title size"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={setings.title_back}
                                        onChange={this.handlePlayerChangeSwitch('title_back')}
                                        value="title_back"
                                        disabled={setings.title_back !== undefined ? false : true}
                                    />

                                }
                                label="title back"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={setings.autoRestart}
                                        onChange={this.handlePlayerChangeSwitch('autoRestart')}
                                        value="autoRestart"
                                        disabled={setings.autoRestart !== undefined ? false : true}
                                    />

                                }
                                label="auto restart"
                            />

                        </FormGroup>

                    </DialogContent>

                </Dialog>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Settings));