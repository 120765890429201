import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import LogIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import { prettiDateTime, shortDate, prettiNum } from '../../services/utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import Logs from '../Logs/Logs';
import Settings from '../Settings/Settings';
import user from '../../store/reducers/user';
import * as network from '../../services';
import ReloadIcon from '@material-ui/icons/Cached';
import RestartIcon from '@material-ui/icons/ScreenRotation';
import IconDownload from '@material-ui/icons/SaveAlt';
import PlaylistIcon from '@material-ui/icons/PlaylistPlay';
import Playlists from '../Playlists/playlist';

let style = theme => ({
    container: {
        display: 'flex',
        height: '100%',
        backgroundColor: '#282c34e0',
        flexDirection: 'column'
    },
    component: {
        display: 'flex',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: 'lightgray',
        fontSize: '12px'
    },
    spacer: {
        flex: '1 1 100%',
    },
    text: {
        display: 'flex',
        paddingTop: '5px',
        width: '200px'
    },
    columnsmall: {
        width: '50px',
        display: 'flex',
        paddingTop: '5px'
    },
    columnbig: {
        width: '200px',
        display: 'flex',
        paddingTop: '5px',
        textAlign: 'justify',
    },
    column: {
        width: '100px',
        display: 'flex',
        paddingTop: '5px'
    },
    downloadicon: {
        color: 'gray',
        paddingTop: '-20px',
        width: '1.1rem'
    },

})

const CustomSlider = withStyles({
    root: {
        color: '#3361b7',
        height: 8,
    },
    thumb: {
        height: 14,
        width: 14,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -3,
        marginLeft: -2,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

function PreteTime(time) {
    return time ? String(Math.floor(time / 3600) + ':' + ('0' + Math.floor(time / 60 % 60)).slice(-2) + ':' + ('0' + Math.floor(time % 60)).slice(-2)) : '0:00:00'
}

function PreteError(error) {
    if (error === null || error === undefined) { return '' }
    const e = error.split(' ');
    switch (e[0] + ' ' + e[1]) {
        case 'Low Balance':
            return e[0] + ' ' + e[1] + '! Totall cost: ' + prettiNum(e[2]) + ' rub per month.'

        default:
            return ''
    }
}

class PlayerUI extends React.Component {
    state = {
        logsOpen: false,
        settingsOpen: false,
        playlistOpen: false,
        type: '*',
        limit: 200,
        offset: 0,
    }

    modeToString(mode) {
        const { modes } = this.props.user;
        var result = '';
        modes.map(m => {
            if (m.mode === mode) {
                result = m.name;
            }
        })
        return result;
    }

    handleLogs = () => {
        const data = {
            id: this.props.player.id,
            type: this.state.type,
            limit: this.state.limit,
            offset: this.state.offset
        }
        this.props.pageActions.getLogs(this.props.user.token, data);
        this.setState({
            logsOpen: !this.state.logsOpen
        })
    }

    handlePlaylist = () => {
        this.setState({
            playlistOpen: !this.state.playlistOpen
        })
    }

    handleReload = () => {
        const { id, own, mac } = this.props.player;
        const socket = network.socket;
        socket.emit('play', { 'playlists': 1, 'player': mac, 'data': 'reload', 'ownid': own });
    }

    handleRestart = () => {
        const { id, own, mac } = this.props.player;
        const socket = network.socket;
        socket.emit('play', { 'player': mac, 'data': 'restart', 'ownid': own });
    }

    handleSettings = () => {
        this.setState({
            settingsOpen: !this.state.settingsOpen
        })
    }

    handleNextLogs = () => {
        this.setState({
            offset: this.state.offset + 200
        })
        const data = {
            id: this.props.player.id,
            type: this.state.type,
            limit: this.state.limit,
            offset: this.state.offset
        }
        this.props.pageActions.getLogs(this.props.user.token, data);
    }

    handlePrevLogs = () => {
        this.setState({
            offset: this.state.offset >= 200 ? this.state.offset - 200 : 0
        })
        const data = {
            id: this.props.player.id,
            type: this.state.type,
            limit: this.state.limit,
            offset: this.state.offset
        }
        this.props.pageActions.getLogs(this.props.user.token, data);
    }

    render() {
        const { classes, key, user, player, usr } = this.props;
        const { id, name, comment, file, duration, totalld, online, setings, own, mode, audiovolume, expires } = this.props.player;
        const { getLogs } = this.props.pageActions;
        const { logsOpen, settingsOpen, playerData, playlistOpen } = this.state;
        const { logs } = this.props.page;
        const errormessage = PreteError(user.errormessage);


        return (
            <div style={{ padding: '5px' }}>
                <Card>
                    <div className={classes.container}>
                        <table key={key}>
                            {/* <thead> */}
                            <tbody key={key}>
                                <tr className={classes.component} key={key}>
                                    <td className={classes.columnsmall} style={{ fontSize: '10px' }}>
                                        id: {id}
                                    </td>
                                    {/* <div className={classes.spacer}></div> */}
                                    <td className={classes.columnbig} style={{ fontSize: '12px' }}>
                                        name: {name}
                                    </td>
                                    <td className={classes.column}>
                                        own: {own}
                                    </td>
                                    <td className={classes.column}>
                                        <Tooltip title="Get logs">
                                            <IconButton color='inherit' onClick={this.handleLogs}>
                                                <LogIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Settings">
                                            <IconButton color='inherit' onClick={this.handleSettings}>
                                                <SettingsIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </td>
                                    <td className={classes.columnbig}>
                                        API: {setings.API}
                                    </td>
                                    <td className={classes.columnbig} style={{ flexDirection: 'column' }}>
                                        Mode: {this.modeToString(mode)}
                                        <br></br>
                                        Balance: {prettiNum(usr.balance)} р.
                                        <br></br>
                                        <p style={{ margin: 0, color: 'red' }}>
                                            {errormessage !== '' ? (errormessage.length >= 25 ? <marquee behavior='alternate' scrollamount='1' width='150'> {errormessage} </marquee> : errormessage) : null}
                                        </p>
                                    </td>
                                    <td className={classes.columnbig}>
                                        Disc: {prettiNum(setings.freediscspace)} MB / {prettiNum(setings.discspace)} MB
                                    </td>
                                </tr>

                                <tr className={classes.component} key={key}>
                                    <td className={classes.columnsmall}>
                                        {player.P_name ?
                                            <IconDownload className={classes.downloadicon} /> : null}
                                    </td>
                                    <td className={classes.columnbig}>
                                        {comment}
                                    </td>
                                    <td className={classes.column}>

                                    </td>
                                    <td className={classes.column}>
                                        <Tooltip title="Reload player">
                                            <IconButton color='inherit' onClick={this.handleReload}>
                                                <ReloadIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Restart player">
                                            <IconButton color='inherit' onClick={this.handleRestart}>
                                                <RestartIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </td>

                                    <td className={classes.columnbig}>
                                        Version: {setings.VersionName}
                                    </td>
                                    <td className={classes.columnbig}>
                                        EXP: {expires ? expires.slice(' ', 10) : null}
                                    </td>
                                    <td className={classes.columnbig}>
                                        Memory: {setings.AppMemory} MB
                                    </td>

                                </tr>

                                <tr className={classes.component} key={key}>
                                    <td style={{ padding: '0px', width: '50px', display: 'flex', fontSize: '8px', paddingTop: '15px' }}>
                                        {PreteTime(duration)}
                                    </td>
                                    <td className={classes.columnbig} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                        <CustomSlider key={id} aria-labelledby="continuous-slider" color='secondary' ValueLabelComponent={ValueLabelComponent} value={duration === undefined ? 0 : duration} max={totalld} disabled={duration === undefined ? true : false} />
                                    </td>
                                    <td className={classes.columnsmall} style={{ padding: '0px', fontSize: '8px', paddingTop: '11px', paddingLeft: '15px', width: '80px' }}>
                                        {PreteTime(totalld)}
                                    </td>
                                    <td className={classes.column}>
                                        <Tooltip title="Playlist">
                                            <IconButton color='inherit' onClick={this.handlePlaylist} >
                                                <PlaylistIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </td>
                                    <td className={classes.columnbig}>
                                        Time: {prettiDateTime(setings.dateNow)}
                                    </td>
                                    <td className={classes.columnbig}>
                                        <VolumeDown style={{ paddingRight: '10px' }} />
                                        <Slider key={id} value={audiovolume === undefined ? 0 : audiovolume} max={15} aria-labelledby="continuous-slider" ValueLabelComponent={ValueLabelComponent} style={{ width: '100px' }} disabled={audiovolume === undefined ? true : false} />
                                        <VolumeUp style={{ paddingLeft: '10px' }} />
                                    </td>
                                    <td className={classes.columnbig}>
                                        Manufacture: {setings.deviceManufacture}
                                    </td>
                                    <td className={classes.columnbig}>
                                        Board: {setings.deviceBoard}
                                    </td>
                                </tr>

                                <tr className={classes.component} key={key}>
                                    <td className={classes.columnsmall}>
                                        {online ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}
                                    </td>
                                    <td className={classes.columnbig} style={{ paddingLeft: '10px' }}>
                                        {file ? file.length >= 25 ? <marquee behavior="alternate" scrollamount="1" width='200px'>{file}</marquee> : <div >{file}</div> : <div></div>}
                                    </td>
                                    <td className={classes.columnbig} style={{ width: 190 }}>

                                    </td>

                                    <td className={classes.columnbig}>
                                        UpTime: {shortDate(setings.UpTime)}
                                    </td>
                                    <td className={classes.columnbig}>
                                        AppUpTime: {shortDate(setings.AppUpTime)}
                                    </td>
                                    <td className={classes.columnbig}>
                                        Model: {setings.deviceModel}
                                    </td>
                                    <td className={classes.columnbig}>
                                        ID: {setings.deviceID}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
                <Logs open={logsOpen} handleLogs={this.handleLogs} id={id} name={name} handleNextLogs={this.handleNextLogs.bind(this)} handlePrevLogs={this.handlePrevLogs.bind(this)} />
                <Settings open={settingsOpen} handleSettings={this.handleSettings} playerdata={player} />
                <Playlists open={playlistOpen} handlePlaylist={this.handlePlaylist} id={id} name={name} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(PlayerUI));