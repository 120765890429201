import { network } from '../services';
import { PATHS, ROOT_API_URL } from '../constants/urls';

class Api {
  registerUser = (data) => network.post(PATHS.registration, data);

  registerListener = (token, data) => network.post(PATHS.registrationListener, data, { headers: { "Authorization": `Token: ${token}` } });

  authorizeUser = (login, passwd) => network.post(PATHS.authorization, { login, passwd });

  changePassword = (token, data) => network.post(PATHS.changepassword, data, { headers: { "Authorization": `Token: ${token}` } });

  getUserData = (token) => network(PATHS.user, { headers: { "Authorization": `Token: ${token}` } });

  getContent = (token) => network.get(PATHS.content, { headers: { "Authorization": `Token: ${token}` } });

  getPlayers = (token) => network.get(PATHS.players, { headers: { "Authorization": `Token: ${token}` } });

  deletePlayer = (token, id) => network.post(`${PATHS.deletePlayer}/${id}`, {}, { headers: { "Authorization": `Token: ${token}` } });

  getPlayer = (token, id) => network.get(`${PATHS.players}/${id}`, { headers: { "Authorization": `Token: ${token}` } });

  updatePlayer = (token, id, data) => network.post(`${PATHS.players}/${id}`, data, { headers: { "Authorization": `Token: ${token}` } });

  deleteContent = (token, data) => network.post(PATHS.deletecontent, data, { headers: { "Authorization": `Token: ${token}` } });

  deleteStream = (token, data) => network.post(PATHS.deletestream, data, { headers: { "Authorization": `Token: ${token}` } });

  renameContent = (token, name, data) => network.post(`${PATHS.renamecontent}/${name}`, data, { headers: { "Authorization": `Token: ${token}` } })

  logout = (token) => network.post(`/api/auth/logout`, {}, { headers: { "Authorization": `Token: ${token}` } });

  uploadContent = (data, token) => network.post('/api/content/upload', data, {
    headers: { "Authorization": `Token: ${token}` },
    onUploadProgress: progressEvent => {
      const loaded = progressEvent.loaded
      const total = progressEvent.total
      console.log(loaded, total)
      // return { loaded, total }
    }
  });

  getPlaylists = (token) => network(PATHS.playlists, { headers: { "Authorization": `Token: ${token}` } });

  getPackPlaylists = (token) => network(PATHS.pacsplaylist, { headers: { "Authorization": `Token: ${token}` } });

  createPlaylist = (token, data) => network.post(PATHS.createplaylist, data, { headers: { "Authorization": `Token: ${token}` } });

  editPlaylist = (token, data) => network.post(PATHS.editplaylist, data, { headers: { "Authorization": `Token: ${token}` } });

  deletePlaylist = (token, data) => network.post(PATHS.deleteplaylist, data, { headers: { "Authorization": `Token: ${token}` } });

  addPlaylistToPlayer = (token, data) => network.post(PATHS.addplaylisttoplayer, data, { headers: { "Authorization": `Token: ${token}` } });

  getPreviewJpeg = (token, name) => network.get(`${PATHS.getPreviewJpeg}/${name}`, { headers: { "Authorization": `Token: ${token}` } });

  uploadLogo = (token, data) => network.post(`${PATHS.uploadLogo}`, data, { headers: { "Authorization": `Token: ${token}` } });

  getLogo = (token) => network.get(`${PATHS.uploadLogo}`, { responseType: 'arraybuffer', headers: { "Authorization": `Token: ${token}`, "Cache-Control": "no-cache" } });

  checkpacsplaylist = (token, data) => network.post(`${PATHS.checkpacsplaylist}`, data, { headers: { "Authorization": `Token: ${token}` } });

  setpacsplaylist = (token, data) => network.post(`${PATHS.setpacsplaylist}`, data, { headers: { "Authorization": `Token: ${token}` } });

  getLogs = (token, data) => network.post(PATHS.getLogs, data, { headers: { "Authorization": `Token: ${token}` } });

  getLogIds = (token) => network.get(PATHS.getLogIds, { headers: { "Authorization": `Token: ${token}` } });

  getModes = (token) => network.get(PATHS.getmodes, { headers: { "Authorization": `Token: ${token}` } });

  changeModes = (token, data) => network.post(PATHS.changemode, data, { headers: { "Authorization": `Token: ${token}` } });

  sendConfirmMessage = (token) => network.post(`${PATHS.sendconfirmmessage}`, '', { headers: { "Authorization": `Token: ${token}` } })

  setListenIds = (token, data) => network.post(PATHS.setlistenids, data, { headers: { "Authorization": `Token: ${token}` } })

  deleteListener = (token, data) => network.post(PATHS.deletelistener, data, { headers: { "Authorization": `Token: ${token}` } })

  getpromotioncode = (token) => network.get(PATHS.getpromotioncode, { headers: { "Authorization": `Token: ${token}` } })

  getusersdata = (token) => network.get(PATHS.getusersdata, { headers: { "Authorization": `Token: ${token}` }})
};

export default new Api();