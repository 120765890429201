import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import UserSelect from '../Players/UserSelect';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import SortIcon from '@material-ui/icons/Sort';

let style = theme => ({
    container: {
        display: 'flex',
        // height: '100%',
        backgroundColor: '#282c34e0',
        flexDirection: 'column',
        paddingTop: '70px'
    },
    component: {
        display: 'flex',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    spacer: {
        flex: '1 1 100%',
    },
    root: {
        display: 'flowRoot'
    },
    buttons: {
        margin: '0 auto',
        paddingTop: '15px'
    },
    players:
    {
        alignSelf: 'center',
        paddingLeft: '70px'
    }

})

class PlayerHeader extends React.Component {
    state = {
        checkOnline: true,
        sortById: true
    }

    handleChangeSort() {
        this.setState({
            sortById: !this.state.sortById
        })
    }

    render() {
        const { classes, handleCheckOnline, handleChange, handleChangeTheme, handleChangePage, pageOpen } = this.props;
        const { players } = this.props.page;
        const { usersdata } = this.props.user;
        const { checkOnline, user } = this.props.state;
        const { sortById } = this.state;

        return (
            <div className={classes.container}>
                <div className={classes.component}>
                    <FormControlLabel
                        // style={{ paddingTop: '20px' }}
                        control={
                            <Checkbox
                                checked={checkOnline}
                                onChange={handleCheckOnline}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="OnLine"
                    />
                    <UserSelect players={players} user={user} handleChange={handleChange.bind(this)} usersdata={usersdata} sortById={sortById} />
                    <div className={classes.buttons}>
                        <Button onClick={this.handleChangeSort.bind(this)}>
                            <SortIcon />
                        </Button>
                    </div>
                    <div className={classes.players}>
                        Players OnLine : {players.filter(p => p.online).length} Players Totall: {players !== undefined ? players.length : 0}
                    </div>
                    <div className={classes.buttons}>
                        <Button onClick={handleChangeTheme.bind(this)}>
                            Theme
                        </Button>
                    </div>
                    <div className={classes.buttons}>
                        {pageOpen === 'players' ?
                            <Button onClick={handleChangePage.bind(this, 'users')}>
                                USER's
                            </Button> :
                            <Button onClick={handleChangePage.bind(this, 'players')}>
                                Players
                            </Button>}
                    </div>

                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(PlayerHeader));