import React, { Component } from 'react';
import { withStyles, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" >
      {'Copyright © '}
      <Link color="inherit" href="https://www.ihsystems.ru">
        IHSystems
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme) => ({
  footer: {
    textAlign: 'center',
    position: 'fixed',
    bottom: 0,
    width: '100% !important',
    height: '30px !important',
    // background: '#d1e4fc',
    paddingTop: '0.5rem',
    display: 'flex',
    left: '45%',
    color: 'gray'
  },
  copyright: {
    marginTop: '1rem',
    left: '45%'
  }
});

class Footer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.footer}>

        <Copyright className={classes.copyright} />

      </div>

    )
  }


}
export default withStyles(styles)(Footer);