import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../img/logo2.1.png'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://revip.org/">
        ReViP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

let useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "white"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  root: {
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& .MuiTextField-root': {
      color: 'white'
    },
    color: 'white'
  },
  input: {
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& .MuiTextField-root': {
      color: 'white'
    },
    color: '#2EFF22'
  }
});

class SignIn extends React.Component {
  state = {
    login: '',
    password: '',
    loginpage: false,
  };

  handleSubmiteForm = e => {
    e.preventDefault();
    const { login, password } = this.state;
    const { getUserAuthorization, getState } = this.props;
    getUserAuthorization(login, password)
  };

  handleChangeInput = e => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {

    const { classes } = this.props;



    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} alt='Logo' className={classes.large} />
          <Typography component="h1" variant="h5">
            Sign in
        </Typography>
          <form className={classes.form} noValidate onChange={this.handleChangeInput} onSubmit={this.handleSubmiteForm}>
            <TextField
              // variant="outlined"
              InputProps={{className: classes.input, classes: {root: classes.root, focused: classes.input, label: classes.input}}}
              className={classes.input}
              margin="normal"
              // color="secondary"
              required
              fullWidth
              id="login"
              label="Login"
              name="login"
              autoComplete="login"
              autoFocus
            />
            <TextField
              // variant="outlined"
              // color="secondary"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
          </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default withStyles(useStyles)(SignIn);