import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';

let style = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        paddingTop: '3rem'
    },
    table: {
        minWidth: window.innerWidth - 50,
    },
    date: {
        width: '15%'
    },
    id: {
        width: '5%'
    },
    tag: {
        width: '5%'
    },
    message: {
        // width: '40%'
    },
    button: {
        margin: theme.spacing(),
    },
    container: {
        display: 'flex',
        height: '100%',
        backgroundColor: '#282c34e0',
        flexDirection: 'column'
    },
    component: {
        display: 'flex',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    spacer: {
        flex: '1 1 100%',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    paperWidthSm: {
        maxWidth: window.innerWidth - 64
    }
})

class Logs extends React.Component {
    state = {
        search: "",
        isSearch: false,
        logs: [],
    }

    handleSearch() {
        this.setState({
            isSearch: !this.state.isSearch,
            search: ""
        })
    }

    handleChangeSearch = event => {
        this.setState({ search: event.target.value });
    };

    render() {
        const { open, handleLogs, classes, id, name, handleNextLogs, handlePrevLogs } = this.props;
        const { search, isSearch } = this.state;
        const { logs } = this.props.page;


        return (
            <div >
                <Dialog
                    open={open}
                    onClose={handleLogs}
                    fullWidth={true}
                    maxWidth='xl'
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"

                >
                    <DialogTitle id="scroll-dialog-title" style={{ backgroundColor: 'gray' }}>Device ID: {id} ({name})</DialogTitle>
                    <DialogContent dividers={true} style={{ backgroundColor: 'gray' }}>

                        <Table className={classes.table} >

                            <TableHead >

                                <TableRow>
                                    <TableCell className={classes.date} align="center">Server Date</TableCell>
                                    <TableCell className={classes.date} align="center">Player Date</TableCell>
                                    <TableCell className={classes.id} align="center">ID</TableCell>
                                    <TableCell className={classes.tag} align="center">TAG</TableCell>
                                    <TableCell className={classes.message} align="center" > {isSearch ? <Input placeholder="Search" margin="normal" onChange={this.handleChangeSearch.bind(this)} /> : <div onClick={this.handleSearch.bind(this)}> Message </div>} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logs
                                    .filter((e) => {
                                        if (search === "") {
                                            return e.message
                                        } else if (e.message.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                                            return e.message
                                        } return null
                                    })
                                    .map((log, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {log.ctime.split('GMT', 1)[0]}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {log.date}
                                            </TableCell>
                                            <TableCell>{log.pid}</TableCell>
                                            <TableCell>{log.tag}</TableCell>
                                            <TableCell>{log.message}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: 'gray' }}>
                        <Button onClick={handlePrevLogs} color="primary">
                            Prev
                        </Button>
                        <Button onClick={handleLogs} color="primary">
                            Close
                        </Button>
                        <Button onClick={handleNextLogs} color="primary">
                            Next
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Logs));